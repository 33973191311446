import { toast } from "react-toastify"

export const error = (e: any) => {
    console.error(e)
    toast.error(e?.response?.data?.message ? e?.response?.data?.message : 'An error has just occurred, please contact MACC', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}