import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaPowerOff } from "react-icons/fa"
import { useHistory } from "react-router"
import { Tooltip } from "reactstrap"
import { GlobalStoreAction } from "../../config/Lite-Redux/reducerGlobal"

import { AppContext } from "../App"
import { ROUTE } from "../Routes"
import './DisconnectButton.scss'

type PropsDisconnectButton = {
  texte?: boolean,
  icone?: boolean,
  sizeIcon?: number
}

const DisconnectButton = ({ texte = false, icone = false, sizeIcon = 25 }: PropsDisconnectButton) => {
  const { t } = useTranslation()
  const [, dispatch] = useContext(AppContext)
  const history = useHistory()

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const handleDeconnexion = () => {
    dispatch({
      type: GlobalStoreAction.DISCONNECT_USER,
      value: null
    })
    history.push(ROUTE.LOGIN)
  }

  if (!texte && !icone) {
    texte = true
  }

  return (
    <div className='btnDeconnexion' onClick={handleDeconnexion} >
      {texte && <div className='text'>{t('BTN_DECONNEXION.TEXT.se-deconnecter')}</div>}
      {icone &&
        <>
          <FaPowerOff id="iconDisconnect" size={sizeIcon} />
          <Tooltip autohide flip isOpen={tooltipOpen} target="iconDisconnect" toggle={toggle}>{t('BTN_DECONNEXION.TEXT.se-deconnecter')}</Tooltip>
        </>}
    </div>
  )

}

export default DisconnectButton