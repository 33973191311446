import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from "universal-cookie"
import { AppContext } from '../../App'
import ChangeEntreprise from '../../ChangeEntreprise'
import DisconnectButton from '../../DisconnectButton'
import { ROUTE } from '../../Routes'
import { error } from '../../Utils/Error'
import { ServiceType } from '../../Utils/Types'
import { useDomaine } from '../../Utils/Url'
import './AGRIMACCLayout.scss'

export default function AGRIMACCLayout(props: any) {
  const { t } = useTranslation()
  const [state] = useContext(AppContext)
  const publicURL = process.env.PUBLIC_URL
  const domaine = useDomaine()
  const cookies = new Cookies()
  const querySite = cookies.get('QUERY') || ''

  const [coordonneeMACC, setCoordonneeMACC] = useState<undefined | ServiceType>(undefined)
  const langue = localStorage.getItem("i18nextLng") != null ? localStorage.getItem("i18nextLng") : 'fr-FR'
  const autoriserCompte = process.env.REACT_APP_ENABLE_FEATURE_MES_COORDONNEE_BANCAIRE === "true" && state.auth.entreprise?.hasAccessCompteEtMandat

  const changeFavicon = () => {
    // @ts-expect-error 
    document.getElementById("favicon1").href = "/img/agrimacc/favicon-agrimacc-100x100.png"
    // @ts-expect-error 
    document.getElementById("favicon2").href = "/img/agrimacc/favicon-agrimacc-200x200.png"
    // @ts-expect-error 
    document.getElementById("favicon3").href = "/img/agrimacc/favicon-agrimacc-300x300.png"
    // @ts-expect-error 
    document.getElementById("favicon4").content = "/img/agrimacc/flag-agrimacc.png"
    var link = document.createElement('link');

    // set the attributes for link element
    link.rel = 'stylesheet';
    link.id = 'cssagrimacc'

    link.type = 'text/css';

    link.href = '/css/styles-agrimacc.min.css';
    document.getElementsByTagName('HEAD')[0].appendChild(link)
  }

  useEffect(() => {
    changeFavicon()
  }, [])

  useEffect(() => {
    if (localStorage.getItem('gen_AGR') != null) {
      setCoordonneeMACC(JSON.parse(localStorage.getItem('gen_AGR') || ''))
    } else {
      const URL_API: string = `/servicesMACC/reseau/AGR/type/gen?langue=${langue}`
      axios.get(URL_API)
        .then(
          response => {
            setCoordonneeMACC(response.data)
            localStorage.setItem('gen_AGR', JSON.stringify(response.data))
          })
        .catch(e => error(e))
    }
  }, [state, langue])

  const handleActiveMenuItem = (urlMenuItem: string) => {
    if (window.location.href.indexOf(urlMenuItem) > -1) {
      return "current-menu-item"
    } else {
      return ""
    }
  }

  return (
    <div className='macc agrimacc'>
      <div className="direct-menu">
        <a href="#nav">{t('LAYOUT.SCREEN_READER.aller-au-menu')}</a>
        <a href="#main">{t('LAYOUT.SCREEN_READER.aller-au-contenu')}</a>
        <a href="#search">{t('LAYOUT.SCREEN_READER.aller-a-la-recherche')}</a>
      </div>
      <header id="header" role="banner">
        <div className="global-header header-first">
          <div className="header-logo">
            <a href={domaine + querySite} className="custom-logo-link" rel="home">
              <picture className="custom-logo">
                <img width="1140" height="157" src={`${publicURL}/img/bandeau-entete-agrimacc.jpg`} alt="AgriMACC La culture des idées" srcSet={`${publicURL}/img/bandeau-entete-agrimacc.jpg 1140w, ${publicURL}/img/bandeau-entete-agrimacc-300x41.jpg 300w, ${publicURL}/img/bandeau-entete-agrimacc-768x106.jpg 768w, ${publicURL}/img/bandeau-entete-agrimacc-450x62.jpg 450w`} sizes="(max-width: 1140px) 100vw, 1140px" />
              </picture>
            </a>
          </div>
        </div>
        <div className="menu-mobile">
          <button className="menu-mobile-burger"><span className="screen-reader-text">{t("LAYOUT.SCREEN_READER.ouvrir-menu")}</span></button>
        </div>
        <nav id="nav" role="navigation" aria-label="Menu principal">
          <div className="global-header">
            <div className="menu-header-main">
              <ul id="menu-header-main" className="menu-entete" role="menubar">
                <li id="menu-item-73" className="menu-item menu-item-type-post_type_archive menu-item-object-products menu-item-73" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.produits') + querySite} aria-current="page" aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={0}>{t("HEADER.produits")}</a></li>
                <li id="menu-item-34" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-34" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.qui-sommes-nous') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.qui-sommes-nous')}</a>
                  <ul className="sub-menu" role="menu">
                    <li id="menu-item-1037" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1037" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.qui-sommes-nous') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.groupe-MACC')}</a></li>
                    <li id="menu-item-444" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-444" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.nos-valeurs') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.nos-valeurs')}</a></li>
                  </ul>
                </li>
                <li id="menu-item-33" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-33" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.innover-ensemble') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.innover-ensemble')}</a>
                  <ul className="sub-menu" role="menu">
                    <li id="menu-item-1056" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1056" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.innover-ensemble') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.AGRIMACC.innover-et-collaborer-avec-la-MACC')}</a></li>
                    <li id="menu-item-1055" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1055" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.deposer-une-idee') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.deposer-une-idee')}</a></li>
                  </ul>
                </li>
                <li id="menu-item-32" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-32" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.nous-rejoindre') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.nous-rejoindre')}</a>
                  <ul className="sub-menu" role="menu">
                    <li id="menu-item-1072" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1072" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.nous-rejoindre') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.pourquoi-nous-rejoindre')}</a></li>
                    <li id="menu-item-82" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-82" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.le-metier-de-responsable-de-secteur') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.le-metier-de-responsable-de-secteur')}</a></li>
                    <li id="menu-item-85" className="line menu-item menu-item-type-custom menu-item-object-custom menu-item-85" role="none"><a href={t('HEADER.AGRIMACC.LINK.postes-a-pourvoir') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.postes-a-pourvoir')}</a></li>
                    <li id="menu-item-86" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-86" role="none"><a href={t('HEADER.AGRIMACC.LINK.candidature-spontanee') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.candidature-spontanee')}</a></li>
                  </ul>
                </li>
                <li id="menu-item-35" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-35 " + handleActiveMenuItem(ROUTE.DASHBOARD)} role="none"><a href={process.env.PUBLIC_URL + ROUTE.DASHBOARD} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.mon-compte')}</a>
                  {state.auth.logged &&
                    <ul className="sub-menu" role="menu">
                      <li id="menu-item-87" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-87" role="none"><a href={process.env.PUBLIC_URL + ROUTE.PARC_PRODUITS} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.mon-parc-produits')}</a></li>
                      <li id="menu-item-88" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-88" role="none"><a href={process.env.PUBLIC_URL + ROUTE.INFOS_PERSONNEL} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.mes-informations')}</a></li>
                      <li id="menu-item-89" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-89" role="none"><a href={process.env.PUBLIC_URL + ROUTE.FACTURES} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.mes-factures')}</a></li>
                      {autoriserCompte && <li id="menu-item-90" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-90" role="none"><a href={process.env.PUBLIC_URL + ROUTE.COORDONNEE_BANCAIRE} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.mes-coordonnees-bancaires')}</a></li>}
                    </ul>
                  }
                </li>
                <li id="menu-item-1026" className={"menu-item menu-item-type-custom menu-item-object-custom menu-item-1026 " + handleActiveMenuItem(ROUTE.INTERLOCUTEURS)} role="none"><a href={process.env.PUBLIC_URL + ROUTE.INTERLOCUTEURS} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.contact')}</a></li>
                <li id="menu-item-169" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-169" role="none"><a href={domaine + t('HEADER.AGRIMACC.LINK.faq') + querySite} aria-haspopup="true" aria-expanded="false" role="menuitem" tabIndex={-1}>{t('HEADER.faq')}</a></li>
              </ul>
            </div>
            <a href="/" className="logo-mobile"><img src={`${publicURL}/img/logo-agrimacc.png`} height="45" width="95" alt="AgriMACC" /></a>
            <div className="header-tools">
              <div className="header-link-macc">
                <a href="https://www.macc.fr"><img src="/img/logo-macc-header.png" alt="MACC France" height="28" width="65" /></a>
              </div>
              <div className="header-search">
                <button className="js-modal" data-modal-content-id="modal-search" data-modal-title="Recherche" data-modal-close-text="Fermer" data-modal-close-title="Fermer cette fenêtre"><span className="icon-search" aria-hidden="true"></span><span className="screen-reader-text">{t('LAYOUT.SCREEN_READER.recherche')}</span></button>
              </div>
              <div className="header-basket">
                <a href={domaine + "/panier/"}>
                  <span className="screen-reader-text">{t('LAYOUT.SCREEN_READER.aller-au-panier')}</span>
                  <span className="icon-basket" aria-hidden="true"></span>
                  {state.panier && state.panier.length > 0 && <span className="num">{state.panier.length}</span>}
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <main id="main" role="main">
        <div className="global-page">
          <div className="content-styles-macc">
            <div className='header-logged'>
              {state.auth.logged && state.auth.entreprise && <ChangeEntreprise />}
              {state.auth.logged && state.auth.entreprise && <DisconnectButton icone />}
            </div>
            {props.children}
          </div>
        </div>

        <div id="modal-search" className="content-modal-hidden">
          <form role="search" method="get" className="search-form" action={domaine + querySite}>
            <label>
              <span className="screen-reader-text">{t('LAYOUT.rechercher')}</span>
              <input type="search" className="search-field" name="s" title={t('LAYOUT.rechercher')} />
            </label>
            <input type="submit" className="search-submit" value={t('LAYOUT.rechercher') as string} />
          </form>
        </div>
      </main>

      <footer id="footer" role="contentinfo">
        <div className="global-footer">
          <div className="footer-contacts">
            <section className="col">
              <div className="col-content">
                {coordonneeMACC &&
                  <>
                    <address>
                      <p><strong>{coordonneeMACC.adresseTitre} &#8211; </strong>{coordonneeMACC.adresseLigne1} &#8211; {coordonneeMACC.adresseLigne2} &#8211; {coordonneeMACC.codePostal} {coordonneeMACC.ville}</p>
                    </address>
                    {coordonneeMACC.listSousServices.map((sousService) =>
                      <div className="phone-email" key={sousService.nomSousService}>
                        <div><span className="icon-phone" aria-hidden="true"></span> <a href="tel:33 (0)5 49 02 55 55">{sousService.telephone1}</a></div>
                        <div><span className="icon-email" aria-hidden="true"></span> <a href={'mailto:' + sousService.mail1 + '@' + sousService.mail2}>{sousService.mail1 + '@' + sousService.mail2}</a></div>
                      </div>
                    )}
                  </>
                }
              </div>
              <nav role="navigation" className="footer-menu-pages">
                <div className="footer-menu">
                  <ul id="menu-pied-de-page" className="menu">
                    <li id="menu-item-69" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-69"><a href={domaine + "engagement-de-protection-des-donnees-a-caractere-personnel/" + querySite} role="menuitem" tabIndex={-1}>{t("LAYOUT.rgpd")}</a></li>
                    <li id="menu-item-51" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-51"><a href={domaine + "mentions-legales/" + querySite} role="menuitem" tabIndex={-1}>{t("LAYOUT.mentions-legales")}</a></li>
                    <li id="menu-item-52" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-52"><a href={domaine + "plan-de-site/" + querySite} role="menuitem" tabIndex={-1}>{t("LAYOUT.plan-site")}</a></li>
                  </ul>
                </div>
                <span className="socials">
                  {t("LAYOUT.suivez_nous")}
                  <a href="https://www.linkedin.com/company/macc-group/" target="_blank" rel="noreferrer"><span className="screen-reader-text">{t('LAYOUT.SCREEN_READER.linkedin')}</span><span className="icon-linkedin" aria-hidden="true"></span></a>
                </span>
              </nav>
            </section>
          </div>
        </div>
      </footer>
    </div>
  )

} 