import Bugsnag from "@bugsnag/js"
import dayjs from "dayjs"
import { useContext } from "react"

import { Redirect, Route, RouteProps } from "react-router-dom"
import { dureeValiditeInformationClient } from "../config/config"

import { AppContext } from "./App"
import { ROUTE } from './Routes'

const ProtectedRoute = (props: RouteProps) => {

  const [state] = useContext(AppContext)



  if (state.auth.logged) {
    Bugsnag.setUser(
      state.auth.user.uuidCompte,
      state.auth.user.infosConnexion.emailLogin,
      state.auth.user.infosConnexion.emailLogin)

    if (state.auth.entreprise) {
      if (dayjs().subtract(dureeValiditeInformationClient, 'year').isAfter(state.auth.entreprise.derniereMiseAJour) || state.auth.entreprise.derniereMiseAJour === null) {
        if (props.path?.includes(ROUTE.CHOIX_ENTREPRISE) || props.path?.includes(ROUTE.CONFIRMATION_INFORMATION)) {
          return <Route {...props} />
        } else {
          return <Redirect to={ROUTE.CONFIRMATION_INFORMATION} />
        }
      }
    }

    return <Route {...props} />
  }

  return <Redirect to={ROUTE.LOGIN} />
}

export default ProtectedRoute