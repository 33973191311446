import React, { useContext, useState } from "react"
import { AppContext } from "../App"

import { useHistory } from "react-router"
import { ROUTE } from "../Routes"

import './ChangeEntreprise.scss'
import { FaPeopleArrows } from "react-icons/fa"
import { Tooltip } from "reactstrap"
import { useTranslation } from "react-i18next"

type PropsChangeEntreprise = {
  sizeIcon?: number
}

export default function ChangeEntreprise({ sizeIcon = 25 }: PropsChangeEntreprise) {
  const { t } = useTranslation()
  const [state] = useContext(AppContext)
  const history = useHistory()

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const handleChangeCompte = () => {
    history.push(ROUTE.CHOIX_ENTREPRISE)
  }

  return (
    <>
      {state.auth.entreprise &&
        <div id="changer-entreprise">
          <div className="entreprise-raison">
            <b>{state.auth.entreprise.formeJuridique} {state.auth.entreprise.raisonSocial} {state.auth.entreprise.immatriculation && <>( {state.auth.entreprise?.immatriculation} )</>} </b>
          </div>
          {state.auth.user.entreprises.length > 1 &&
            <>
              <FaPeopleArrows id="iconChange" className="change-button" onClick={handleChangeCompte} size={sizeIcon} />
              <Tooltip autohide flip isOpen={tooltipOpen} target="iconChange" toggle={toggle}>{t('BTN_CHANGE_ENTREPRISE.TOOLTIP.change-entreprise')}</Tooltip>
            </>
          }
        </div>
      }
    </>
  )
}