import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { langue } from '../locales/index'
import Cookies from "universal-cookie"

export const LANGUE = langue

const langueUser = new URLSearchParams(window.location.search).get("lang")?.replace('_', '-')

i18next
  .use(LanguageDetector)
  .init({
    initImmediate: false,
    resources: langue,
    fallbackLng: {
      'de-DE': ['de-BE'],
      'de': ['de-BE'],
      'it': ['it-IT'],
      'es': ['es-ES'],
      'en': ['en-GB'],
      'nl': ['nl-NL'],
      'default': ['fr-FR']
    },
    returnEmptyString: false,
    returnNull: false,
    debug: true
  }, function (err, t) {
    console.log(err + '' + t)
  })

const cookies = new Cookies();
if (langueUser && cookies.get('isLogged') !== 'true') {
  console.log('Force lang en ' + langueUser)
  i18next.changeLanguage(langueUser)
} else {
  console.log('Force lang en ' + langueUser, !cookies.get('isLogged'), cookies.get('isLogged') !== 'true')
}

export default i18next