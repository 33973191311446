import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './components/App'
import './config/i18next'



Bugsnag.start({
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: getEnvironement(),
    apiKey: 'ef91a7013e9bd8426ad74561640445d6',
    plugins: [new BugsnagPluginReact()],
    enabledErrorTypes: {
        unhandledRejections: false,
        unhandledExceptions: true,
    }

})

function getEnvironement() {
    if (hostnameContains('localhost'))
        return 'localhost';
    if (hostnameContains('/dev.'))
        return 'development';
    if (hostnameContains('/recette.'))
        return 'recette';
    return 'production';
}
function hostnameContains(term: string) {
    return window.location.hostname.includes(term);
}

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)


ReactDOM.render(
    ErrorBoundary ?
        <ErrorBoundary>
            <App />
        </ErrorBoundary> : <App />
    , document.getElementById('root')
)