import axios from 'axios'
import React, { useReducer } from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import reducerGlobal, { ActionType, globalStoreValueType, initialGlobalStoreValue } from '../config/Lite-Redux/reducerGlobal'
import i18n from '../config/i18next'
import './App.scss'
import Routes from './Routes'

var initialContextValue: [globalStoreValueType, React.Dispatch<ActionType>] = [initialGlobalStoreValue, () => { }]

export const AppContext = React.createContext(initialContextValue)

function App() {
  const contextValue = useReducer(reducerGlobal, initialGlobalStoreValue)

  const port = window.location.port ? ':' + window.location.port : ''
  const domaine = document.domain !== 'localhost' ? window.location.protocol + '//' + document.domain + port + '/api' : process.env.REACT_APP_API
  axios.defaults.baseURL = domaine

  return (
    <I18nextProvider i18n={i18n}>
      <AppContext.Provider value={contextValue}>
        <BrowserRouter>
          <Routes />
          <ToastContainer
            style={{ zIndex: 10000 }}
            theme="colored"
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </BrowserRouter>
      </AppContext.Provider>
    </I18nextProvider>
  );
}

export default App
