
interface ArrayString {
    [key: string]: string;
}


const domainByLanguePROD: ArrayString = {
    'fr-FR': 'https://www.macc.fr/',
    'fr-BE': 'https://www.maccbenelux.be/fr/',
    'de-BE': 'https://www.maccbenelux.be/de/',
    'nl-BE': 'https://www.maccbenelux.be/nl/',
    'de-LU': 'https://www.maccbenelux.be/de/',
    'fr-LU': 'https://www.maccbenelux.be/fr/',
    'nl-NL': 'https://www.maccnederland.nl/',
    'en-GB': 'https://www.macc-uk.com/',
    'en-UK': 'https://www.macc-uk.com/',
    'es-ES': 'https://www.macc.es/',
    'it-IT': 'https://www.maccitalia.it/',
    'AGRI': 'https://www.agrimacc.fr/'
}


const domainByLangueDEV: ArrayString = {
    'fr-FR': 'https://macc-fr.e-labo.io/',
    'fr-BE': 'https://macc-benelux.e-labo.io/fr/',
    'de-BE': 'https://macc-benelux.e-labo.io/de/',
    'nl-BE': 'https://macc-benelux.e-labo.io/nl/',
    'de-LU': 'https://macc-benelux.e-labo.io/de/',
    'fr-LU': 'https://macc-benelux.e-labo.io/fr/',
    'nl-NL': 'https://macc-benelux.e-labo.io/nl/',
    'en-GB': 'https://macc-uk.e-labo.io/',
    'en-UK': 'https://macc-uk.e-labo.io/',
    'es-ES': 'https://macc-es.e-labo.io/',
    'it-IT': 'https://macc-it.e-labo.io/',
    'AGRI': 'https://agrimacc.e-labo.io/'
}
const domainByLangue = document.domain !== 'localhost' && !document.domain.includes('dev.') && !document.domain.includes('recette.') ? domainByLanguePROD : domainByLangueDEV

export const useDomaine = () => {
    let langue: string = localStorage.getItem("i18nextLng") || 'fr-FR'

    return getDomaineByLangue(langue) || domainByLangue['fr-FR']
}

const getDomaineByLangue = (langue: string) => {
    console.log(langue, domainByLangue[langue])
    if (langue === 'fr-FR' && 'AGRI' === localStorage.getItem('theme'))
        return domainByLangue['AGRI']
    return domainByLangue[langue]
}
