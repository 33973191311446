export const getReseauFromLangue = (langue: string | null) => {
  const theme = localStorage.getItem('theme') != null ? localStorage.getItem('theme') : ""
  if (theme === 'AGRI')
    return 'AGR'
  let langueLwr = langue ? langue.toUpperCase() : 'FR-FR'
  switch (langueLwr) {
    case 'DE-BE':
    case 'DE-DE':
    case 'FR-BE':
    case 'NL-BE':
    case 'DE-LU':
    case 'DE-NL':
    case 'FR-LU':
    case 'LB-LU':
    case 'NL-NL':
    case 'BE':
    case 'DE':
    case 'NL':
      return 'BLG'

    case 'EN-GB':
    case 'EN-UK':
    case 'EN':
      return 'GBR'

    case 'ES':
    case 'ES-ES':
      return 'ESP'

    case 'IT-IT':
    case 'IT':
      return 'ITA'

    default:
      return 'FRA'
  }
}

export const getSocieteFromLangue = (langue: string | null) => {
  let langueLwr = langue ? langue.toUpperCase() : 'FR-FR'
  switch (langueLwr) {
    case 'DE-BE':
    case 'FR-BE':
    case 'NL-BE':
    case 'DE-NL':
    case 'DE-LU':
    case 'FR-LU':
    case 'LB-LU':
    case 'NL-NL':
      return 'BL'

    case 'EN-GB':
    case 'EN-UK':
      return 'GB'

    case 'ES-ES':
      return 'ES'

    case 'IT-IT':
    case 'IT':
      return 'IT'

    default:
      return 'FR'
  }
}

export const getPaysFromLangue = (langue: string | null) => {
  let langueLwr = langue ? langue.toUpperCase() : 'FR-FR'
  switch (langueLwr) {
    case 'DE-BE':
    case 'FR-BE':
    case 'NL-BE':
      return 'BE'
    case 'DE-LU':
    case 'FR-LU':
    case 'LB-LU':
      return 'LU'
    case 'NL-NL':
    case 'DE-NL':
      return 'NL'
    case 'DE-DE':
      return 'DE'
    case 'EN-GB':
    case 'EN-UK':
      return 'GB'
    case 'ES-ES':
      return 'ES'
    case 'IT-IT':
    case 'IT':
      return 'IT'
    default:
      return 'FR'
  }
}