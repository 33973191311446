import React, { useContext, useEffect } from 'react'
import './ReseauLayout.scss'

import BATIMACCLayout from '../BATIMACCLayout'
import AGRIMACCLayout from '../AGRIMACCLayout'
import { AppContext } from '../../App'
import { GlobalStoreAction } from '../../../config/Lite-Redux/reducerGlobal'

export default function ReseauLayout(props: any) {

  const [state, dispatch] = useContext(AppContext)
  let theme = localStorage.getItem('theme') != null ? localStorage.getItem('theme') : state?.theme

  // ======= Gestion langue depuis site institutionnel =======
  const site = new URLSearchParams(window.location.search).get("site")
  useEffect(() => {
    if (site) {
      if (site === 'agrimacc') {
        dispatch({ type: GlobalStoreAction.CHANGE_THEME, value: 'AGRI' })
      } else if (site === 'macc') {
        dispatch({ type: GlobalStoreAction.CHANGE_THEME, value: 'MACC' })
      }
      localStorage.setItem('SITE', site)
    }
  }, [site, dispatch])
  // =======================================

  function renderLayout() {
    if (theme === 'AGRI') {
      return (
        <AGRIMACCLayout>
          {props.children}
        </AGRIMACCLayout>
      )
    } else {
      return (
        <BATIMACCLayout>
          {props.children}
        </BATIMACCLayout>
      )
    }
  }

  return (
    <>
      <div className='MACC'>
        {renderLayout()}
      </div>
    </>
  )
}