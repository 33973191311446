import { EntrepriseWebType, MoyenContactWebType } from "../../components/Utils/Types"
import { ActionType } from "./reducerGlobal"

const inscrString = localStorage.getItem('INSCRIPTION')
const inscr = inscrString ? JSON.parse(inscrString) : undefined

export enum InscriptionStoreAction {
  SET_EMAIL = 'SET_EMAIL',
  SET_IMMATRICULATION = 'SET_IMMATRICULATION',
  SET_MOBILE = "SET_MOBILE",
  SET_HASH = "SET_HASH",
  SET_DATE_EXECUTION = "SET_DATE_EXECUTION",
  SET_CODE = "SET_CODE",
  SET_UUID_COMPTE = 'SET_UUID_COMPTE',
  SET_TOKEN = 'SET_TOKEN',
  SET_ENTREPRISES_TROUVEES = 'SET_ENTREPRISES_TROUVEES',
  SET_ENTREPRISES_CHOISIES = 'SET_ENTREPRISES_CHOISIES',
  SET_ID_ENTREPRISES_CHOISIES = 'SET_ID_ENTREPRISES_CHOISIES',
  SET_MOYENS_CONTACTS_TROUVES = 'SET_MOYENS_CONTACTS_TROUVES',
  SET_MOYEN_CONTACT_CHOISI = 'SET_MOYEN_CONTACT_CHOISI'
}

export type inscriptionStoreValueType = {
  email: string,
  immatriculation: string,
  mobile: number,
  hash: string,
  dateExecution: string,
  code: string,
  uuidCompte?: string,
  token?: string,
  entreprisesTrouvees: EntrepriseWebType[],
  idEntreprisesChoisies: number[],
  entreprisesChoisies: EntrepriseWebType[],
  moyensContactsTrouves: MoyenContactWebType[],
  moyenContactChoisi?: MoyenContactWebType
}

export const initialInscriptionStoreValue: inscriptionStoreValueType = inscr || {
  email: '',
  immatriculation: '',
  pays: '',
  mobile: 0,
  hash: '',
  dateExecution: '',
  code: '',
  uuidCompte: undefined,
  token: undefined,
  entreprisesTrouvees: [],
  entreprisesChoisies: [],
  idEntreprisesChoisies: [],
  moyensContactsTrouves: [],
  moyenContactChoisi: undefined
}

const reducerInscription = (state: inscriptionStoreValueType, action: ActionType) => {
  let inscription
  switch (action.type) {
    case InscriptionStoreAction.SET_EMAIL:
      inscription = Object.assign(initialInscriptionStoreValue, { email: action.value })
      break
    case InscriptionStoreAction.SET_IMMATRICULATION:
      inscription = Object.assign({ ...state }, { immatriculation: action.value.immat, pays: action.value.pays })
      break
    case InscriptionStoreAction.SET_MOBILE:
      inscription = Object.assign({ ...state }, { mobile: action.value })
      break
    case InscriptionStoreAction.SET_HASH:
      inscription = Object.assign({ ...state }, { hash: action.value })
      break
    case InscriptionStoreAction.SET_DATE_EXECUTION:
      inscription = Object.assign({ ...state }, { dateExecution: action.value })
      break
    case InscriptionStoreAction.SET_CODE:
      inscription = Object.assign({ ...state }, { code: action.value })
      break
    case InscriptionStoreAction.SET_UUID_COMPTE:
      inscription = Object.assign({ ...state }, { uuidCompte: action.value })
      break
    case InscriptionStoreAction.SET_TOKEN:
      inscription = Object.assign({ ...state }, { token: action.value })
      break
    case InscriptionStoreAction.SET_ENTREPRISES_TROUVEES:
      inscription = Object.assign({ ...state }, { entreprisesTrouvees: action.value })
      break
    case InscriptionStoreAction.SET_ID_ENTREPRISES_CHOISIES:
      inscription = Object.assign({ ...state }, { idEntreprisesChoisies: action.value })
      break
    case InscriptionStoreAction.SET_ENTREPRISES_CHOISIES:
      inscription = Object.assign({ ...state }, { entreprisesChoisies: action.value })
      break
    case InscriptionStoreAction.SET_MOYENS_CONTACTS_TROUVES:
      inscription = Object.assign({ ...state }, { moyensContactsTrouves: action.value })
      break
    case InscriptionStoreAction.SET_MOYEN_CONTACT_CHOISI:
      inscription = Object.assign({ ...state }, { moyenContactChoisi: action.value })
      break
    default:
      throw new Error('No action available')
  }
  localStorage.setItem('INSCRIPTION', JSON.stringify(inscription))
  return inscription;
}

export default reducerInscription