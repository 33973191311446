import React, { useReducer } from "react";
import { ActionType } from "../../../config/Lite-Redux/reducerGlobal";
import reducerInscription, { initialInscriptionStoreValue } from "../../../config/Lite-Redux/reducerInscription";

var initialContextValue : [any, React.Dispatch<ActionType>] = [initialInscriptionStoreValue, () => { }]

export const InscriptionContext = React.createContext(initialContextValue)

export default function Context (props: any) {
  const inscriptionContextValue = useReducer(reducerInscription, initialInscriptionStoreValue)
  
  return (
    <InscriptionContext.Provider value={inscriptionContextValue}>
      {props.children}
    </InscriptionContext.Provider>
  )
}
