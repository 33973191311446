import deBE from './de-BE.json'
import deLU from './de-LU.json'
import enGB from './en-GB.json'
import esES from './es-ES.json'
import frBE from './fr-BE.json'
import frFR from './fr-FR.json'
import frLU from './fr-LU.json'
import itIT from './it-IT.json'
import nlBE from './nl-BE.json'
import nlNL from './nl-NL.json'

export const langue = {
  fr: { translation: frFR },
  'de-BE': { translation: deBE },
  'de-LU': { translation: deLU },
  'en-GB': { translation: enGB },
  'en-UK': { translation: enGB },
  'es-ES': { translation: esES },
  'fr-BE': { translation: frBE },
  'fr-FR': { translation: frFR },
  'fr-LU': { translation: frLU },
  'it-IT': { translation: itIT },
  'nl-BE': { translation: nlBE },
  'nl-NL': { translation: nlNL }
}
  