import { lazy, Suspense, useContext, useEffect } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import i18next, { LANGUE } from '../config/i18next'

import axios from 'axios'
import Cookies from "universal-cookie"
import { GlobalStoreAction } from '../config/Lite-Redux/reducerGlobal'
import { AppContext } from './App'
import Context from './Inscription/Context'
import ReseauLayout from './Layouts/ReseauLayout'
import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'

// PAGES
const ActiveAGRI = lazy(() => import('./Utils/ActiveAGRI/ActiveAGRI'))
const Login = lazy(() => import('../pages/Login'))
const Account = lazy(() => import('../pages/Account'))
const ChoixEntreprise = lazy(() => import('../pages/ChoixEntreprise'))
const Produits = lazy(() => import('../pages/Produits'))
const Maintenance = lazy(() => import('../pages/Maintenance'))
const Facture = lazy(() => import('../pages/Facture'))
const Interlocuteurs = lazy(() => import('../pages/Interlocuteurs'))
const Conseiller = lazy(() => import('../pages/Conseiller'))
const InfosPersonnel = lazy(() => import('../pages/InfosPersonnel'))
const UpdatePassword = lazy(() => import('../components/FormPassword/UpdatePassword'))
const ChangePassword = lazy(() => import('../components/FormPassword/ChangePassword'))
const ProduitsDetails = lazy(() => import('../pages/ProduitsDetails'))
const Inscription = lazy(() => import('../pages/Inscription'))
const FormulaireContact = lazy(() => import('../pages/FromulaireContact'))
const Panier = lazy(() => import('../pages/Panier'))
const MotDePasseOublie = lazy(() => import('../pages/MotDePasseOublie'))
const ConfirmationInformation = lazy(() => import('../pages/ConfirmationInformation'))
const CoordonneeBancaire = lazy(() => import('../pages/CoordonneeBancaire'))


const getRoutes = (chemin: string): string[] => {
  return [ // @ts-expect-error 
    LANGUE['fr-FR'].translation.ROUTE[chemin], // @ts-expect-error 
    LANGUE['de-BE'].translation.ROUTE[chemin], // @ts-expect-error 
    LANGUE['de-LU'].translation.ROUTE[chemin], // @ts-expect-error 
    LANGUE['en-GB'].translation.ROUTE[chemin], // @ts-expect-error 
    LANGUE['es-ES'].translation.ROUTE[chemin], // @ts-expect-error 
    LANGUE['fr-BE'].translation.ROUTE[chemin], // @ts-expect-error 
    LANGUE['fr-LU'].translation.ROUTE[chemin], // @ts-expect-error 
    LANGUE['it-IT'].translation.ROUTE[chemin], // @ts-expect-error 
    LANGUE['nl-BE'].translation.ROUTE[chemin], // @ts-expect-error 
    LANGUE['nl-NL'].translation.ROUTE[chemin]
  ].filter(url => url)
}

const ROUTES = {
  LOGIN: getRoutes('LOGIN'),
  MAINTENANCE: getRoutes('MAINTENANCE'),
  AGRIMACC: getRoutes('AGRIMACC'),
  INSCRIPTION: getRoutes('INSCRIPTION'),
  DASHBOARD: getRoutes('DASHBOARD'),
  CHOIX_ENTREPRISE: getRoutes('CHOIX_ENTREPRISE'),
  FACTURES: getRoutes('FACTURES'),
  INTERLOCUTEURS: getRoutes('INTERLOCUTEURS'),
  CONSEILLER: getRoutes('CONSEILLER'),
  INFOS_PERSONNEL: getRoutes('INFOS_PERSONNEL'),
  PARC_PRODUITS: getRoutes('PARC_PRODUITS'),
  PARC_PRODUITS_DETAIL: getRoutes('PARC_PRODUITS_DETAIL'),
  PARC_PRODUITS_DETAIL_SAV: getRoutes('PARC_PRODUITS_DETAIL_SAV'),
  FORMULAIRE_CONTACT: getRoutes('FORMULAIRE_CONTACT'),
  PANIER: getRoutes('PANIER'),
  PANIER_VALIDER: getRoutes('PANIER_VALIDER'),
  LOGIN_PANIER: getRoutes('LOGIN_PANIER'),
  MOT_DE_PASSE_OUBLIE: getRoutes('MOT_DE_PASSE_OUBLIE'),
  MOT_DE_PASSE_UPDATE: getRoutes('MOT_DE_PASSE_UPDATE'),
  CONFIRMATION_INFORMATION: getRoutes('CONFIRMATION_INFORMATION'),
  AIDE_CONNEXION: getRoutes('AIDE_CONNEXION'),
  COORDONNEE_BANCAIRE: getRoutes('COORDONNEE_BANCAIRE')
}

export const ROUTE = {
  LOGIN: i18next.t('ROUTE.LOGIN'),
  MAINTENANCE: i18next.t('ROUTE.MAINTENANCE'),
  AGRIMACC: i18next.t('ROUTE.AGRIMACC'),
  INSCRIPTION: i18next.t('ROUTE.INSCRIPTION'),
  DASHBOARD: i18next.t('ROUTE.DASHBOARD'),
  CHOIX_ENTREPRISE: i18next.t('ROUTE.CHOIX_ENTREPRISE'),
  FACTURES: i18next.t('ROUTE.FACTURES'),
  INTERLOCUTEURS: i18next.t('ROUTE.INTERLOCUTEURS'),
  CONSEILLER: i18next.t('ROUTE.CONSEILLER'),
  INFOS_PERSONNEL: i18next.t('ROUTE.INFOS_PERSONNEL'),
  PARC_PRODUITS: i18next.t('ROUTE.PARC_PRODUITS'),
  PARC_PRODUITS_DETAIL: i18next.t('ROUTE.PARC_PRODUITS_DETAIL'),
  PARC_PRODUITS_DETAIL_SAV: i18next.t('ROUTE.PARC_PRODUITS_DETAIL_SAV'),
  FORMULAIRE_CONTACT: i18next.t('ROUTE.FORMULAIRE_CONTACT'),
  PANIER: i18next.t('ROUTE.PANIER'),
  PANIER_VALIDER: i18next.t('ROUTE.PANIER_VALIDER'),
  LOGIN_PANIER: i18next.t('ROUTE.LOGIN_PANIER'),
  MOT_DE_PASSE_OUBLIE: i18next.t('ROUTE.MOT_DE_PASSE_OUBLIE'),
  MOT_DE_PASSE_UPDATE: i18next.t('ROUTE.MOT_DE_PASSE_UPDATE'),
  CONFIRMATION_INFORMATION: i18next.t('ROUTE.CONFIRMATION_INFORMATION'),
  AIDE_CONNEXION: i18next.t('ROUTE.AIDE_CONNEXION'),
  COORDONNEE_BANCAIRE: i18next.t('ROUTE.COORDONNEE_BANCAIRE'),
}

const Routes = () => {
  const [state, dispatch] = useContext(AppContext)

  useEffect(() => {
    const cookies = new Cookies()
    const isLogged: boolean = cookies.get('isLogged')

    // Si on est connecté
    if (isLogged) {
      // Traitement de l'action (token) connexionRedirectionSwitch. Si l'utilisateur est connecté,on le déconecte
      const tokenJWT = cookies.get('tokenJWT')
      const idTiersEntreprise = cookies.get('idTiersEntreprise')
      const route: string = cookies.get('route')

      if (tokenJWT && idTiersEntreprise && route) {
        dispatch({ type: GlobalStoreAction.DISCONNECT_USER, value: null })
      }

      // Si on est pas connecté mais que isLogged n'existe pas, on le set a false
    } else {
      cookies.set('isLogged', false, { path: '/' })
      dispatch({ type: GlobalStoreAction.DISCONNECT_USER, value: null })
    }
  }, [dispatch])


  useEffect(() => {
    if (state.auth.token) {
      console.log(state.auth.token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${state.auth.token}`
    }
    else {
      axios.defaults.headers.common['Authorization'] = ``
    }
    const langue: string = localStorage.getItem("i18nextLng") || 'fr-FR'
    axios.defaults.headers.common['X-langue'] = langue
  }, [state])


  // ======= Gestion quantite panier =======
  const panier = new URLSearchParams(window.location.search).get("panier")
  useEffect(() => {
    const listPanier = panier?.split(',')
    if (panier === '' && state.panier && state.panier.length !== 0) {
      dispatch({ type: GlobalStoreAction.DEL_PANIER, value: [] })
    }
    else if (listPanier && listPanier.toString() !== state.panier.toString()) {
      dispatch({ type: GlobalStoreAction.UPDATE_PANIER, value: listPanier })
    }
  }, [panier, state, dispatch])
  // =======================================


  axios.interceptors.response.use(response => response, error => {
    if (!axios.isCancel(error)) {
      if (401 === error?.response?.status) {
        dispatch({ type: "DISCONNECT_USER", value: null })
      }
      else if (429 === error?.response?.status) {
        dispatch({ type: "DISCONNECT_USER", value: null })
      }
      else if (413 === error?.response?.status) {
        dispatch({ type: "DISCONNECT_USER", value: null })
      }
    }
    return Promise.reject(error)
  })

  return (
    <Suspense fallback={<div className='d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3'><div className='w-50 mx-auto'>Loading...</div></div>}>
      <ReseauLayout>
        <Switch>
          <PublicRoute exact path={ROUTES.AGRIMACC} component={ActiveAGRI} />
          <PublicRoute exact path={ROUTES.MAINTENANCE} component={Maintenance} />
          <ProtectedRoute path={ROUTES.FORMULAIRE_CONTACT} component={FormulaireContact} />
          <ProtectedRoute path={ROUTES.CONSEILLER} component={Conseiller} />
          <PublicRoute path={ROUTES.INTERLOCUTEURS} component={Interlocuteurs} />
          <PublicRoute path={ROUTES.PANIER} component={Panier} />
          <PublicRoute path={ROUTES.PANIER_VALIDER} component={() => { return (<Panier valide={true}></Panier>) }} />
          <PublicRoute restricted exact path={ROUTES.LOGIN} component={Login} />
          <PublicRoute restricted exact path={ROUTES.LOGIN_PANIER} component={() => { return (<Login panier={true}></Login>) }} />
          <PublicRoute restricted path={ROUTES.INSCRIPTION} render={() => { return (<Context><Inscription /></Context>) }} />
          <PublicRoute path={ROUTES.MOT_DE_PASSE_OUBLIE} component={MotDePasseOublie} />
          <PublicRoute path={ROUTES.AIDE_CONNEXION} render={() => { return (<div><ChangePassword /></div>) }} />
          <ProtectedRoute path={ROUTES.MOT_DE_PASSE_UPDATE} render={() => { return (<div><UpdatePassword /></div>) }} />
          <ProtectedRoute path={ROUTES.CHOIX_ENTREPRISE} component={ChoixEntreprise} />
          <ProtectedRoute path={ROUTES.CONFIRMATION_INFORMATION} component={ConfirmationInformation} />
          <ProtectedRoute path={ROUTES.PARC_PRODUITS_DETAIL_SAV} render={() => { return (<ProduitsDetails sav={true} />) }} />
          <ProtectedRoute path={ROUTES.PARC_PRODUITS_DETAIL} render={() => { return (<ProduitsDetails sav={false} />) }} />
          <ProtectedRoute path={ROUTES.PARC_PRODUITS} component={Produits} />
          <ProtectedRoute path={ROUTES.FACTURES} render={() => { return <div><Facture /></div> }} />
          <ProtectedRoute path={ROUTES.INFOS_PERSONNEL} component={InfosPersonnel} />
          {process.env.REACT_APP_ENABLE_FEATURE_MES_COORDONNEE_BANCAIRE === "true" && state.auth.entreprise?.hasAccessCompteEtMandat &&
            <ProtectedRoute path={ROUTES.COORDONNEE_BANCAIRE} component={CoordonneeBancaire} />}
          {/** Dashboard en dernier car il accepte toutes les urls qui commencent par /mon-compte */}
          <ProtectedRoute path={ROUTES.DASHBOARD} component={Account} />
          <PublicRoute path='/' render={() => { return (<Redirect to={ROUTE.LOGIN} />) }} />
        </Switch>
      </ReseauLayout>
    </Suspense>
  )
}

export default Routes