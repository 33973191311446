import React, { useContext } from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { AppContext } from "./App"
import { ROUTE } from "./Routes"

type PublicRouteProps = {
  restricted?: boolean
}

const PublicRoute = (props: PublicRouteProps & RouteProps) => {
  const { restricted = false, ...rest } = props

  const [state] = useContext(AppContext)

  if (state.auth.logged && restricted) {
    return <Redirect to={ROUTE.DASHBOARD} />
  }

  return (<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}><Route {...rest} /></GoogleReCaptchaProvider >)
}

export default PublicRoute