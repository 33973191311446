import Cookies from "universal-cookie";
import { EntrepriseWebType, InformationsWebType } from "../../components/Utils/Types";

const cookies = new Cookies();
const authString = localStorage.getItem('AUTH')
const auth = authString ? JSON.parse(authString) : undefined

export type ActionType = {
  type: string,
  value: any
}

export enum GlobalStoreAction {
  CONNECT_USER = 'CONNECT_USER',
  CHOIX_ENTREPRISE = 'CHOIX_ENTREPRISE',
  DISCONNECT_USER = 'DISCONNECT_USER',
  ADD_REDIRECTION = 'ADD_REDIRECTION',
  DEL_REDIRECTION = 'DEL_REDIRECTION',
  DEL_PANIER = 'DEL_PANIER',
  UPDATE_PANIER = 'UPDATE_PANIER',
  USER_PANIER = 'USER_PANIER',
  CHANGE_THEME = 'CHANGE_THEME'
}

export type globalStoreValueType = {
  auth: {
    logged: boolean,
    token: string,
    user: InformationsWebType,
    entreprise?: EntrepriseWebType
  },
  theme: string,
  redirection?: string,
  panier: string[],
  userPanier: {
    mobile: string,
    immat: string;
    pays: string,
    nom: string,
    societe: string,
    langue: string,
  }
}

export const initialGlobalStoreValue: globalStoreValueType = auth || {
  auth: {
    logged: false,
    token: undefined,
    user: {},
    entreprise: {},
  },
  theme: 'MACC',
  redirection: undefined,
  panier: [],
  userPanier: {
    mobile: undefined,
    immat: undefined,
    pays: undefined,
    nom: undefined,
    societe: undefined,
    langue: undefined,
  }
}

const reducerGlobal = (state: globalStoreValueType, action: ActionType) => {
  let auth
  switch (action.type) {
    case GlobalStoreAction.CONNECT_USER:
      const actionValueConnectUser: InformationsWebType = action.value
      const theme = actionValueConnectUser.codeReseau === 'AGR' ? 'AGRI' : 'MACC'
      localStorage.setItem('theme', theme)
      localStorage.setItem('source', 'COMPTE')
      const entreprise = actionValueConnectUser.entreprises.length > 0 ? actionValueConnectUser.entreprises[0] : undefined
      auth = Object.assign(
        { ...state },
        {
          ...state,
          auth: {
            ...state.auth,
            logged: true,
            user: actionValueConnectUser,
            token: entreprise ? entreprise.token : actionValueConnectUser.token,
            entreprise: entreprise
          },
          theme: theme
        })
      cookies.set('isLogged', true, { path: '/' })
      break
    case GlobalStoreAction.CHOIX_ENTREPRISE:
      console.log('choix entreprise', state, action)
      const actionValueChoixEntreprise: number = action.value
      const entreprisesChoisi = state.auth.user.entreprises.filter(
        (entreprise: EntrepriseWebType) => entreprise.idTier === actionValueChoixEntreprise)
      const entrepriseChoisi = entreprisesChoisi.length > 0 ? entreprisesChoisi[0] : undefined
      const themeEntreprise = entrepriseChoisi && entrepriseChoisi.codeReseau === 'AGR' ? 'AGRI' : 'MACC'
      localStorage.setItem('theme', themeEntreprise)
      localStorage.setItem('source', 'COMPTE')
      auth = Object.assign(
        { ...state },
        {
          ...state,
          auth: {
            ...state.auth,
            token: entrepriseChoisi ? entrepriseChoisi.token : state.auth.token,
            entreprise: entrepriseChoisi,
            user: {
              ...state.auth.user,
              codeReseau: entrepriseChoisi ? entrepriseChoisi.codeReseau : state.auth.user.codeReseau
            }
          },
          theme: themeEntreprise
        })
      break
    case GlobalStoreAction.DISCONNECT_USER:
      localStorage.removeItem('source')
      auth = Object.assign(
        { ...state },
        {
          auth: {
            ...state.auth,
            logged: false,
            user: {},
            token: null,
            entreprise: {}
          }
        })
      cookies.set('isLogged', false, { path: '/' })
      break
    case GlobalStoreAction.ADD_REDIRECTION:
      auth = Object.assign({ ...state }, { redirection: action.value })
      break
    case GlobalStoreAction.DEL_REDIRECTION:
      auth = Object.assign({ ...state }, { redirection: undefined })
      break
    case GlobalStoreAction.DEL_PANIER:
      auth = Object.assign({ ...state }, {
        redirection: undefined, panier: [], userPanier: { mobile: undefined, immat: undefined, pays: undefined, nom: undefined, societe: undefined, langue: undefined, }
      })
      break
    case GlobalStoreAction.UPDATE_PANIER:
      auth = Object.assign({ ...state }, { panier: action.value })
      cookies.remove('QUERY')
      break
    case GlobalStoreAction.USER_PANIER:
      auth = Object.assign({ ...state }, { userPanier: action.value })
      break;
    case GlobalStoreAction.CHANGE_THEME:
      if (localStorage.getItem('source') !== 'COMPTE')
        localStorage.setItem('theme', action.value)
      auth = Object.assign({ ...state }, { theme: action.value })
      break
    default:
      throw new Error('No action available')
  }
  localStorage.setItem('AUTH', JSON.stringify(auth))
  return auth;
}

export default reducerGlobal